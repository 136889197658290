import {
  Container,
  Count,
  Text,
  Wrapper,
  NumberWrapper,
  StyledImage,
} from "./styled";
import details from "./../../../assets/images/details.png";
import { useEffect, useState } from "react";

export const Details = () => {
  const [operaciones, setOperaciones] = useState(0);
  const [clientes, setClientes] = useState(0);

  // Simulación de incremento animado para los contadores
  useEffect(() => {
    const operacionesTimer = setInterval(() => {
      setOperaciones((prev) => (prev < 170 ? prev + 1 : prev));
    }, 10);

    const clientesTimer = setInterval(() => {
      setClientes((prev) => (prev < 220 ? prev + 1 : prev));
    }, 10);

    return () => {
      clearInterval(operacionesTimer);
      clearInterval(clientesTimer);
    };
  }, []);

  return (
    <Container>
      <Wrapper>
        <Text>
          <span>Tu asesor inmobiliario de confianza</span>
          <br />
          <b>Brindando soluciones para todas tus necesidades</b>
          <br />
        </Text>

        <NumberWrapper>
          <div>
            <Count className="operations">{`+${operaciones}`}</Count>
            <span>Operaciones realizadas</span>
          </div>
          <div>
            <Count className="clients">{`+${clientes}`}</Count>
            <span>Clientes satisfechos</span>
          </div>
        </NumberWrapper>
      </Wrapper>
      <Wrapper>
        <StyledImage src={details} alt="Detalles" />
      </Wrapper>
    </Container>
  );
};
