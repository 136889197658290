import { Home } from "./section_1";
import { Services } from "./section_2";
import { Details } from "./section_3";
import { Estate } from "./section_4";

export const Body = () => {
  return (
    <>
      <Home />
      <Services />
      <Details />
      <Estate />
    </>
  );
};
