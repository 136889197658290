import {
  Button,
  Container,
  Text,
  Title,
  Wrapper,
  ImageContainer,
  ImageWrapper,
  ButtonGroup,
  Overlay,
  ImageText,
} from "./styled";
import german3 from "./../../../assets/images/photo2.jpg";
import german2 from "./../../../assets/images/photo1.jpg";

export const Home = () => {
  return (
    <Container id="home">
      <Wrapper>
        <Title>Armanino Negocios Inmobiliarios</Title>
        <Text>
          Con confianza y transparencia, te acompañamos en la{" "}
          <b>COMPRA-VENTA</b> de tu inmueble
        </Text>
        <ImageWrapper>
          <ImageContainer>
            <img src={german2} alt="Armanino German" />
            <Overlay>
              <ImageText>Armanino German</ImageText>
            </Overlay>
          </ImageContainer>
          <ImageContainer>
            <img src={german3} alt="Rodriguez Natalia" />
            <Overlay>
              <ImageText>Rodriguez Natalia</ImageText>
            </Overlay>
          </ImageContainer>
        </ImageWrapper>

        <ButtonGroup>
          <Button primary>
            <a
              href="#estate"
              onClick={(e) => {
                e.preventDefault();
                document.querySelector("#estate").scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              Ver Propiedades
            </a>
          </Button>

          <Button secondary>
            <a
              href="#contact"
              onClick={(e) => {
                e.preventDefault();
                document.querySelector("#contact").scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              Contáctenos
            </a>
          </Button>
        </ButtonGroup>
      </Wrapper>
    </Container>
  );
};
