import styled, { keyframes } from "styled-components";

export const heartButton = keyframes`
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
`;

export const Container = styled.label`
  background-color: white;
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 10px 15px 10px 10px;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: black;
`;

export const Input = styled.input``;

export const Action = styled.div`
  position: relative;
  overflow: hidden;
  display: grid;
`;

export const Option1 = styled.span`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  transform: ${(props) =>
    props.checked ? "translate(0px,-100%)" : "translate(0px,0%)"};
  opacity: ${(props) => (props.checked ? 0 : 1)};
  transition: all 0.5s;
`;
