import {
  Card,
  CardButton,
  CardImg,
  CardSubtitle,
  CardText,
  CheckboxContainer,
  Container,
  Title,
  FiltersWrapper,
  EstateGrid,
  ShowMoreButton,
} from "./styled";
import { useState, useEffect } from "react";
import axios from "axios";
import { Loader } from "./../../loader";
import { Checkbox } from "./checkbox";

const assetsType = {
  1: "Terreno",
  2: "Departamento",
  3: "Casa",
  7: "Local",
  10: "Cochera",
};

export const Estate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [visibleItems, setVisibleItems] = useState(10); 
  const [operations, setOperation] = useState([
    { id: 1, name: "Venta", description: "Sale", checked: false },
    { id: 2, name: "Alquiler", description: "Rent", checked: false },
  ]);
  const [types, setTypes] = useState([
    { id: 3, name: "Departamento", description: "Apartment", checked: false },
    { id: 4, name: "Casa", description: "House", checked: false },
    { id: 5, name: "Oficina", description: "Business Premises", checked: false },
    { id: 6, name: "Cochera", description: "Garage", checked: false },
  ]);

  const [resultsFound, setResultsFound] = useState(true);

  useEffect(() => {
    setFilteredData(data.slice(0, visibleItems));
  }, [data, visibleItems]);

  const handleChangeChecked = (id, type) => {
    const update = type.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    type === types ? setTypes(update) : setOperation(update);
  };

  const applyFilters = () => {
    let updatedList = data;

    const typeChecked = types.filter((item) => item.checked).map((item) => item.description);
    if (typeChecked.length) {
      updatedList = updatedList.filter((item) => typeChecked.includes(item.type));
    }

    const operationChecked = operations.filter((item) => item.checked).map((item) => item.description);
    if (operationChecked.length) {
      updatedList = updatedList.filter((item) => operationChecked.includes(item.operation));
    }

    setFilteredData(updatedList.slice(0, visibleItems));
    setResultsFound(updatedList.length > 0);
  };

  useEffect(() => {
    applyFilters();
  }, [operations, types]);

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_APP_URL}${process.env.REACT_APP_APP_KEY}`);
      setData(res.data.objects.map((el) => ({
        id: el.id,
        status: el.status,
        type: el.type.name,
        typeId: el.type.id,
        operation: el.operations[0].operation_type,
        image: el.photos[0].image,
        title: el.publication_title,
        url: el.public_url,
      })));
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleShowMore = () => {
    setVisibleItems((prev) => prev + 10); 
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Container id="estate">
      <Title>Nuestras Propiedades</Title>
      <FiltersWrapper>
        <CheckboxContainer>
          {operations.map((item) => (
            <Checkbox
              key={item.id}
              name={item.name}
              handleChange={() => handleChangeChecked(item.id, operations)}
              checked={item.checked}
            />
          ))}
        </CheckboxContainer>
        <CheckboxContainer>
          {types.map((item) => (
            <Checkbox
              key={item.id}
              name={item.name}
              handleChange={() => handleChangeChecked(item.id, types)}
              checked={item.checked}
            />
          ))}
        </CheckboxContainer>
      </FiltersWrapper>

      <EstateGrid>
        {filteredData.map((item) => (
          <Card key={item.id}>
            <CardImg src={item.image} />
            <div>
              <CardSubtitle>{item.operation === "Sale" ? "Venta" : "Alquiler"}</CardSubtitle>
              <CardSubtitle>Propiedad: {assetsType[item.typeId]}</CardSubtitle>
              <CardText>{item.title}</CardText>
              <CardButton onClick={() => window.location.href = item.url}>Más información</CardButton>
            </div>
          </Card>
        ))}
      </EstateGrid>

      {visibleItems < data.length && (
        <ShowMoreButton onClick={handleShowMore}>Ver más propiedades</ShowMoreButton>
      )}
    </Container>
  );
};