import styled from "styled-components";

export const NavbarStyled = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  translate: ${({ isNavbarVisible, isMenuOpen }) =>
    isMenuOpen ? "0" : isNavbarVisible ? "0" : "0 -100px"};
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  background: #ffff;
  transition: 0.3s ease-in-out;
  z-index: 100;

  img {
    height: 100px;
    width: 100px;
  }
`;

export const NavItems = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }

  a {
    text-decoration: none;
    color: #000;
    height: 36px;
    display: grid;
    place-items: center;
  }

  a.active {
    color: #fff;
    background: #000;
    padding: 0 16px;
    border-radius: 10px;

    &:hover {
      transform: scale(1.1);
      transition: 0.3s ease-in-out;
    }
  }
`;
