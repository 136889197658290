import React from "react";
import { SpinnerContainer, LoadingSpinner } from "./styled";

export const Loader = ({ size }) => {
  return (
    <SpinnerContainer>
      <LoadingSpinner size={size} />
    </SpinnerContainer>
  );
};
