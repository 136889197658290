import { FaRegBuilding } from "react-icons/fa";

import { Card, Container, IconContainer, List, Title, Wrapper } from "./styled";

export const Services = () => {
  const cards = [
    {
      id: 1,
      title: "Compra y venta de propiedades",
      list: ["Asesoramiento", "Tasación", "Desarrollo"],
      color: "#e71e43",
    },
    {
      id: 2,
      title: "Administración de Propiedades",
      list: ["Administración y mantenimiento", "Publicación", "Marketing"],
      color: "#000",
    },
    {
      id: 3,
      title: "Asesoramiento en inversiones inmobiliarias",
      list: ["Inversión a corto y largo plazo", "Emprendimiento", "Créditos"],
      color: "#e71e43",
    },
  ];

  return (
    <Container id="services">
      <Wrapper>
        {cards.map((card) => (
          <Card key={card.id}>
            <IconContainer color={card.color}>
              <FaRegBuilding />
            </IconContainer>
            <Title>{card.title}</Title>
            <List>
              {card.list.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </List>
          </Card>
        ))}
      </Wrapper>
    </Container>
  );
};
