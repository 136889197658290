import { Navbar } from "./components/navbar";
import { Body } from "./components/body";
import { Footer } from "./components/footer";
import { WhatappComponent } from "./components/whatapp";

export const App = () => {
  return (
    <>
      <WhatappComponent />
      <Navbar />
      <Body />
      <Footer />
    </>
  );
};
