import styled from "styled-components";

export const Container = styled.section`
  padding: 2rem 1rem;
  background-color: #f9f9f9;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
`;

export const Card = styled.article`
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 320px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

export const IconContainer = styled.div`
  font-size: 3rem;
  color: ${(props) => props.color};
  margin-bottom: 1rem;
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  color: #222;
  margin-bottom: 1rem;
`;

export const List = styled.ul`
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;

  li {
    font-size: 1rem;
    color: #555;
    position: relative;
    padding-left: 1.5rem;

    &::before {
      content: "✔";
      color: ${(props) => props.color || "#e71e43"};
      position: absolute;
      left: 0;
    }
  }
`;
