import styled, { keyframes } from "styled-components";

export const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled.div`
  width: ${({ size }) => size || "50px"};
  height: ${({ size }) => size || "50px"};
  border: 2px solid #f3f3f3;
  border-top: 2px solid #383636;
  border-radius: 50%;
  animation: ${spinner} 1.5s linear infinite;
`;

export const SpinnerContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  height: 150px;
`;
