import styled from "styled-components";

export const Container = styled.div`
  padding: 8rem 5rem 2rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  max-width: 800px;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: clamp(2rem, 5vw, 3rem); /* Tamaño adaptable */
  margin-bottom: 1rem;
`;

export const Text = styled.p`
  font-size: 1.1rem;
  margin-bottom: 2rem;
`;

// Estilo para el grupo de botones
export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Button = styled.button`
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
  margin-top: 1rem;

  a {
    text-decoration: none;
    color: ${(props) => (props.primary ? "white" : "black")};
  }

  background-color: ${(props) => (props.primary ? "#000000" : "#f8f9fa")};

  &:hover {
    transform: scale(1.1);
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const ImageContainer = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover img {
    opacity: 0.8;
  }

  &:hover div {
    opacity: 0.8;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

export const ImageText = styled.span`
  color: white;
  font-size: 1.2rem;
  text-align: center;
`;
