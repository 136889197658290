import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: #25d366;
  color: #fff;

  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;

  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.5s;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: #fff;
`;
