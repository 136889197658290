import { Container, Icon,  Title, Wrapper } from "./styled";

import footer from "./../../assets/images/footer.png";

import { BiMap } from "react-icons/bi";

import {
  BsFacebook,
  BsInstagram,
  BsTelephoneFill,
  BsWhatsapp,
} from "react-icons/bs";

import { MdEmail } from "react-icons/md";

export const Footer = () => {
  return (
    <Container id="contact">
      <Wrapper>
        <Title>Contactanos</Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
            padding: "0.5rem",
          }}
        >
          <BiMap size={16} />
          <span>
            Av. Tronador 2054 - Barrio Parque Capital
            Cordoba - Argentina
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
            padding: "0.5rem",
          }}
        >
          <BsTelephoneFill size={16} />
          <span>+543518048837</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "1rem",
            padding: "0.5rem",
          }}
        >
          <MdEmail size={16} />
          <span>gerarmanino@hotmail.com</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "3rem",
            marginTop: "1rem",
            width: "100%",
          }}
        >
          <Icon
            onClick={() =>
              window.open("https://www.instagram.com/armaninoinmobiliaria/")
            }
          >
            <BsInstagram size={32} cursor={"pointer"} />
          </Icon>

          <Icon
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send/?phone=3518048837&text=Hola%2C+estoy+interesado%2Fa+en...+&type=phone_number&app_absent=0"
              )
            }
          >
            <BsWhatsapp size={32} cursor={"pointer"} />
          </Icon>
          <Icon
            onClick={() =>
              window.open(
                "https://www.facebook.com/armaninonegociosinmobiliarios"
              )
            }
          >
            <BsFacebook size={32} cursor={"pointer"} />
          </Icon>
          {/* <Icon>
            <BsLinkedin size={32} cursor={"pointer"} />
          </Icon> */}
        </div>
      </Wrapper>

      <Wrapper>
        <img src={footer} alt="" />
      </Wrapper>
    </Container>
  );
};
