import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  background-color: #f5f5f5;
  padding: 4rem 2rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 45%;

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
    align-items: center;
  }
`;

export const Text = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 2rem;
  color: #333;

  span {
    font-weight: 400;
    display: block;
    font-size: 1.75rem;
    color: #222;
  }

  b {
    font-weight: 600;
    font-size: 2rem;
    color: #e71e43;
  }
`;

export const NumberWrapper = styled.div`
  display: flex;
  gap: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1.5rem;
  }

  div {
    text-align: center;
  }
`;

export const Count = styled.h2`
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  &.clients {
    color: #e71e43;
  }
`;

export const StyledImage = styled.img`
  @media (max-width: 768px) {
    margin-top: 1rem;
    max-width: 300px;
  }
`;
