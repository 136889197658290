import { useEffect, useRef, useState } from "react";
import { NavItems, NavbarStyled } from "./styled";
import { FiMenu } from "react-icons/fi";

import logo from "./../../assets/images/logo.png";

export const Navbar = () => {
  const lastScrollTop = useRef(0);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isItemVisible, setIsItemVisible] = useState(true);

  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const items = [
    {
      id: 1,
      name: "Home",
      to: "#home",
      active: "",
    },
    {
      id: 2,
      name: "Servicios",
      to: "#services",
      active: "",
    },
    {
      id: 3,
      name: "Propiedades",
      to: "#estate",
      active: "",
    },
    {
      id: 4,
      name: "Contacto",
      to: "#contact",
      active: "active",
    },
  ];

  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        var { pageYOffset } = window;
        if (pageYOffset > lastScrollTop.current) {
          setIsNavbarVisible(false);
        } else if (pageYOffset < lastScrollTop.current) {
          setIsNavbarVisible(true);
        }
        lastScrollTop.current = pageYOffset <= 0 ? 0 : pageYOffset;
      },
      { passive: true }
    );
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsItemVisible(false);
      } else {
        if (isMenuOpen) {
          setIsMenuOpen(false);
          console.log(isMenuOpen);
        }
        setIsItemVisible(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMenuOpen]);

  return (
    <>
      <NavbarStyled isNavbarVisible={isNavbarVisible} isMenuOpen={isMenuOpen}>
        <img src={logo} alt="logo" />
        <NavItems
          style={{
            display: isItemVisible ? "flex" : "none",
          }}
        >
          {items.map((item) => (
            <a
              key={item.id}
              href={`${item.to}`}
              className={item.active}
              onClick={(e) => {
                e.preventDefault();
                document.querySelector(`${item.to}`).scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              {item.name}
            </a>
          ))}
        </NavItems>
        <FiMenu
          size={32}
          color="#000"
          style={{
            cursor: "pointer",
            display: isItemVisible ? "none" : "block",
          }}
          onClick={() => handleMenuOpen()}
        />
      </NavbarStyled>
      {isMenuOpen && (
        <NavItems
          style={{
            position: "fixed",
            top: "100px",
            left: "0",
            right: "0",
            bottom: "0",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "#fff",
            display: "flex",
          }}
        >
          {items.map((item) => (
            <a
              key={item.id}
              href={item.url}
              className={item.active}
              style={{ fontSize: "24px", padding: "16px" }}
              onClick={(e) => {
                e.preventDefault();
                document.querySelector(`${item.to}`).scrollIntoView({
                  behavior: "smooth",
                });
                setIsMenuOpen(false);
              }}
            >
              {item.name}
            </a>
          ))}
        </NavItems>
      )}
    </>
  );
};
