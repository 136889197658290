import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 2rem 3rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 2.75rem;
  line-height: 3.5rem;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.625rem;
  letter-spacing: -0.4px;
`;

export const Button = styled.button`
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #000;
    color: #fff;
  }
  &.first {
    background-color: #000;
    color: #fff;
  }
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.3);
  }
`;

