import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { Container, Link } from "./styled";

export const WhatappComponent = () => {
  return (
    <Container>
      <Link
        href="https://wa.me/3518048837?text=Hola,%20estoy%20interesado/a%20en...%20"
        target="_blank"
        rel="noreferrer"
      >
        <BsWhatsapp size={36} />
      </Link>
    </Container>
  );
};
