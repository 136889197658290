import React from "react";
import { Action, Container, Input, Option1 } from "./styled";

export const Checkbox = ({ name, id, handleChange, checked }) => {
  return (
    <>
      <Container htmlFor="favorite">
        <Input
          id={id}
          type="checkbox"
          name={name}
          onChange={() => handleChange(id)}
          checked={checked}
        />

        <Action className="action">
          <Option1 className="option-1">{name}</Option1>
        </Action>
      </Container>
    </>
  );
};
